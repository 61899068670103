import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { FiCloud, FiServer, FiKey, FiAward, FiTrendingUp, FiUsers } from "react-icons/fi";
import { IconContext } from "react-icons";

const SECURITY_ITEMS = [
  {
    name: 'VIRTUAL PRIVATE CLOUD',
    image: <FiCloud />,
    role: 'interaction smartly connected',
    items: ['Hosted in ISO 27001 certified data centers', 'Customer s choice of data center location', 'Highly secure cloud-based SaaS offering']
  },
  {
    name: 'MULTI-TENANCY',
    image: <FiServer />,
    role: 'Your mode - best for you',
    items: ['Multi-layer tenant data segregation', 'Each tenant could run on a separate domain', 'Customer maintains control over their tenant']
  },
  {
    name: 'FULL ENCRYPTION',
    image: <FiKey />,
    role: 'Instant overview',
    items: ['Mobile app data encryption', 'Uses AES 256 and TLS 1.2 encryption', 'Data is encrypted at rest and in transit']    
  },
  {
    name: 'REGULATORY COMPLIANCE',
    image: <FiAward />,
    role: 'for all of you',
    items: ['Swiss Data Protection Act', 'General Data Protection Regulation (GDPR)', 'ISO 27001:2013 certified']    
  },
  {
    name: 'HIGH AVAILABILITY',
    image: <FiTrendingUp />,
    role: 'No outlook anymore',
    items: ['High availability server instances']
  },
  {
    name: 'CUSTOMER CONTROLS ACCESS',
    image: <FiUsers />,
    role: 'handle your business deals',
    items: ['Role-based permissions for Tasks and Projects']
  },
];


const Security = () => (
  <StaticQuery
    query={graphql`

    query {
      allFile(filter: { sourceInstanceName: { eq: "team" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      art_security: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "undraw_safe_bnk7" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    `}


     
    
    render={data => (
      <Section id="Security">
        <Container>
          <Grid>
            <div>
              <h2>Security</h2>
              <p><small>Your data security is our top priority. That's why we use best-in-class 256-bit TLS encryption, advanced firewall protection and have ISO 27001 certified data centers.</small></p>
            </div>
            <Art>
              <Img fluid={data.art_security.childImageSharp.fluid} />
            </Art>
          </Grid>

          <SecurityFeaturesGrid>
            {SECURITY_ITEMS.map(({ name, image, role, items }) => {
              

              const listItems = items.map((item) =>
                <li>{item}</li>
              );

              return (
                <div>
                  <IconContext.Provider value={{ color: "#FE5A79", size: '30', className: "global-class-name" }}>
                    <div>
                    {image}
                    </div>
                  </IconContext.Provider>


                  <Title>{name}</Title>
                  {/*
                  const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;
              
              <Subtitle>{role}</Subtitle> 
                  <Img fluid={img.childImageSharp.fluid} alt={name} />
                  */}
                  <ul>
                  <Subtitle>{listItems}</Subtitle>
                    
                  </ul>
                </div>
              );
            })}
          </SecurityFeaturesGrid>
          
          <ArtMobile>
            <Img fluid={data.art_security.childImageSharp.fluid} />
          </ArtMobile>

        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 100px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 150px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;




const SecurityFeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const ArtSecurity = styled.figure`
  width: 800px;
  top: 0;
  left: 50%;
  reight: 50%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 50%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -50%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;


export default Security;