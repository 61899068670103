import React from 'react';
import Layout from '@common/Layout';
import NavbarSubpages from '@common/Navbar/NavbarSubpages';
import Security from '../components/sections/Security';
import Footer from '../components/sections/Footer';
import SEO from '@common/SEO';

const ThirdPage = () => (
  <Layout>
    <SEO/>
    <NavbarSubpages />
    <Layout>
    
    
    <Security />


    </Layout>
    <Footer />
  </Layout>
);

export default ThirdPage;